import React, {useEffect, useState} from 'react';
import './style.scss';
import config from './config.json';
import media from '../../media';
import Layout from "../../components/layout/main";
import MainHeading from "../../components/main-heading/main";
import ProjectsGallery from "../../components/projects-gallery/main";

export default function Works(props) {
    //On component mount
    useEffect(() => {
        document.title = config.title;
    }, []);

    return (
        <div className="works">
            <Layout>
                <div className="projects-block">
                    <div className="inner-content">
                        <MainHeading content={config.projects.heading} />

                        <ProjectsGallery />
                    </div>
                </div>
            </Layout>
        </div>
    )
}
