import React, {useEffect, useState} from 'react';
import './style.scss';
import media from '../../media';

export default function StudyBanner(props) {
    return (
        <div className="study-banner">
            <div className="inner-content">
                <img className="logo" src={media[props.content.logo]} />

                <div className="breadcrumb">
                    {props.content.breadcrumb.map((entry, key) => {
                        return(
                            <span className="entry" key={key}>
                                {key!==0 && <span className="separator">{">"}</span>}
                                <a className="link" href={entry.link}>{entry.name}</a>
                            </span>
                        );
                    })}

                    <span className="entry">
                        <span className="separator">{">"}</span>
                        <span className="title">{props.title}</span>
                    </span>
                </div>
            </div>
        </div>
    );
}
