import React, {useEffect, useState} from 'react';
import './style.scss';
import media from '../../media';

export default function Carousel(props) {
    const [scrollDirection, setScrollDirection] = useState(props.scrollDirection?props.scrollDirection:"left");
    const [scrollDuration, setScrollDuration] = useState(props.scrollDuration?props.scrollDuration:10);
    const [animationStarted, setAnimationStarted] = useState(false);

    //On component mount
    useEffect(() => {
        setTimeout(()=>{
            setAnimationStarted(true); //Start animation after 0.5 seconds to solve webfonts loading problem (minor overlap betwwen marquees)
        }, 500)
    }, []);

    return (
        <div className={"carousel"+(animationStarted?" start":"")}>
            <div className="track">
                <div className="marquee marquee1">
                    <div className={"entries"+(scrollDirection==="left"?" scroll-left":" scroll-right")} style={{animationDuration:scrollDuration+"s"}}>
                        {props.children.map((child, key) => {
                            return(
                                <span className="entry" key={key}>
                                    {child}
                                </span>
                            );
                        })}
                    </div>
                </div>
                <div className="marquee marquee2">
                    <div className={"entries"+(scrollDirection==="left"?" scroll-left":" scroll-right")} style={{animationDuration:scrollDuration+"s"}}>
                        {props.children.map((child, key) => {
                            return(
                                <span className="entry" key={key}>
                                    {child}
                                </span>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
}
