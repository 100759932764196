import React, {useEffect, useState} from 'react';
import './style.scss';
import config from './config.json';
import media from '../../media';
import Layout from "../../components/layout/main";
import MainHeading from "../../components/main-heading/main";

export default function NotFound(props) {
    //On component mount
    useEffect(() => {
        document.title = config.title;
    }, []);

    return (
        <div className="not-found">
            <Layout>
                <div className="intro-block">
                    <div className="inner-content">
                        <MainHeading content={config.intro.heading} />

                        <div className="paragraphs">
                            {config.intro.body.map((paragraph, key) => {
                                return(
                                    <p key={key} dangerouslySetInnerHTML={{__html:paragraph}}></p>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </Layout>
        </div>
    )
}
