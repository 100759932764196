import React, {useEffect, useState} from 'react';
import './style.scss';
import config from './config.json';
import media from '../../../media';
import Layout from "../../../components/layout/main";
import StudyWrapper from "../../../components/study-wrapper/main";
import SectionHeading from "../../../components/section-heading/main";
import ProjectsSlider from "../../../components/projects-slider/main";

export default function Gdpr(props) {
    //On component mount
    useEffect(() => {
        document.title = config.title;
    }, []);

    return (
        <div className="gdpr">
            <Layout>
                <StudyWrapper bg={config.bg} banner={config.banner} body={config.body} title={config.title} />

                <div className="projects-block">
                    <div className="inner-content">
                        <SectionHeading text={config.projects.heading} />

                        <ProjectsSlider current={config.projects.current} />
                    </div>
                </div>
            </Layout>
        </div>
    )
}
