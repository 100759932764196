import React, {useEffect, useState} from 'react';
import './style.scss';
import config from './config.json';
import media from '../../media';
import {getWpDocs, simplifyWpDate} from '../../utilities/general';
import Layout from "../../components/layout/main";
import Loader from "../../components/loader/main";
import MainHeading from "../../components/main-heading/main";

export default function Blog(props) {
    const [posts, setPosts] = useState(null);
    const [postsPage, setPostsPage] = useState(1);
    const [filteredPosts, setFilteredPosts] = useState([]);
    const [categories, setCategories] = useState(null);
    const [searchValue, setSearchValue] = useState("");
    const [filter1Enabled, setFilter1Enabled] = useState(false);
    const [filter2Enabled, setFilter2Enabled] = useState(false);
    const [showButtonsHolder, setShowButtonsHolder] = useState(true);
    const [showMoreLoader, setShowMoreLoader] = useState(false);

    //On component mount
    useEffect(() => {
        document.title = config.title;
        loadWpData();
    }, []);

    //On component mount and posts or filters change
    useEffect(() => {
        if (posts!==null) {
            applyFilters(posts);
        }
    }, [posts, filter1Enabled, filter2Enabled]);

    return (
        <div className="blog">
            <Layout>
                <div className="intro-block">
                    <div className="inner-content">
                        <MainHeading content={config.intro.heading} />

                        <div className="controls">
                            <div className="search-box">
                                <form className="search-bar" onSubmit={onSearchSubmit}>
                                    <input className="search-input" type="text" name="search" placeholder="Search..." value={searchValue} onChange={(e)=>{setSearchValue(e.target.value)}} />
                                    <input className="search-btn" type="submit" value="" />
                                </form>
                            </div>
                            <div className="filters">
                                <div className="filter-info">
                                    <span className="text">{config.intro.info}</span>
                                </div>
                                <div className="filter1">
                                    <span className={"filter-btn"+(filter1Enabled?" enabled":"")} onClick={()=>{setFilter1Enabled(filter1Enabled?false:true);}}>{config.intro.filter1}</span>
                                </div>
                                <div className="filter2">
                                    <span className={"filter-btn"+(filter2Enabled?" enabled":"")} onClick={()=>{setFilter2Enabled(filter2Enabled?false:true);}}>{config.intro.filter2}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="posts-block">
                    <div className="inner-content">
                        {posts===null ?
                            <div className="loader-holder">
                                <Loader />
                            </div>
                        :
                            filteredPosts.length>0 ?
                                <div className="preview">
                                    <div className="posts">
                                        {filteredPosts.map((post, key) => {
                                            return(
                                                <div className="post" key={key}>
                                                    <a className="link" href={"/blog/"+post.slug}>
                                                        {post['_embedded'].hasOwnProperty('wp:featuredmedia') ?
                                                            <img src={post['_embedded']['wp:featuredmedia'][0]['source_url']} />
                                                        :
                                                            <img src={media[config.posts.defaultThumb]} />
                                                        }
                                                        <h3 className="title" dangerouslySetInnerHTML={{__html:post.title.rendered}}></h3>
                                                        <span className="date">{post.date}</span>
                                                    </a>
                                                </div>
                                            );
                                        })}
                                    </div>

                                    {showButtonsHolder &&
                                        <div className="buttons-holder">
                                            <div className="more-btn" onClick={onMoreClick}>
                                                <span className="text">{config.posts.moreBtn}</span>
                                                <span className="icon">
                                                    <span className="arrow"></span>
                                                </span>
                                            </div>
                                        </div>
                                    }

                                    {showMoreLoader &&
                                        <div className="loader-holder">
                                            <Loader />
                                        </div>
                                    }
                                </div>
                            :
                                <p className="not-found">{config.posts.notFound}</p>
                        }
                    </div>
                </div>
            </Layout>
        </div>
    )

    async function loadWpData() {
        var recentPosts = await getWpDocs("posts", {_embed:null, per_page:12, page:1});
        var allCategories = await getWpDocs("categories", {per_page:100, page:1});

        optimisePostsData(recentPosts, allCategories);

        //Update state
        setPosts(recentPosts);
        setCategories(allCategories);
    }

    async function onSearchSubmit(e) {
        e.preventDefault();
        
        //Reset necessary states
        setPostsPage(1);
        setPosts(null);
        setFilter1Enabled(false);
        setFilter2Enabled(false);
        setShowButtonsHolder(false);

        //Get posts matching search value
        var searchedPosts = await getWpDocs("posts", {_embed:null, per_page:12, page:1, search:searchValue});

        optimisePostsData(searchedPosts, categories);

        //Update necessary states
        if (searchedPosts.length===12) {
            setShowButtonsHolder(true);
        }

        setPosts(searchedPosts);
    }

    async function onMoreClick(e) {
        e.preventDefault();

        //Clone current posts
        var currentPosts = [...posts];

        //Reset necessary states
        setFilter1Enabled(false);
        setFilter2Enabled(false);
        setShowButtonsHolder(false);

        //Get next posts with current search value
        setShowMoreLoader(true);

        var nextPage = postsPage + 1;
        var nextPosts = await getWpDocs("posts", {_embed:null, per_page:12, page:nextPage, search:searchValue});

        setShowMoreLoader(false);

        if (nextPosts!==-1) {
            optimisePostsData(nextPosts, categories);

            currentPosts.push(...nextPosts); //Add nextPosts to currentPosts array

            //Update necessary states
            if (nextPosts.length===12) {
                setShowButtonsHolder(true);
            }

            setPostsPage(nextPage);
            setPosts(currentPosts);
        }
    }

    function optimisePostsData(postsList, categoriesList) {
        var currCategoryNames = null;
        var i, j;

        for (i=0; i<postsList.length; i++) {
            currCategoryNames = [];

            for (j=0; j<postsList[i].categories.length; j++) {
                currCategoryNames.push(categoriesList.find((category) => category.id===postsList[i].categories[j]).name); //Store post category names
            }

            postsList[i].categories = currCategoryNames; //Replace category ids with category names
            postsList[i].date = simplifyWpDate(postsList[i].date); //Simplify date
        }
    }

    function applyFilters(toFilter) {
        var filtered = [];
        var i;

        if (filter1Enabled) {
            if (filter2Enabled) {
                //Both filters
                for (i=0; i<toFilter.length; i++) {
                    if (toFilter[i].categories.indexOf(config.intro.filter1)!==-1 && toFilter[i].categories.indexOf(config.intro.filter2)!==-1) {
                        filtered.push(toFilter[i]);
                    }
                }
            }
            else {
                //Filter 1 only
                for (i=0; i<toFilter.length; i++) {
                    if (toFilter[i].categories.indexOf(config.intro.filter1)!==-1) {
                        filtered.push(toFilter[i]);
                    }
                }
            }
        }
        else {
            if (filter2Enabled) {
                //Filter 2 only
                for (i=0; i<toFilter.length; i++) {
                    if (toFilter[i].categories.indexOf(config.intro.filter2)!==-1) {
                        filtered.push(toFilter[i]);
                    }
                }
            }
            else {
                //Filter none
                filtered = [...toFilter];
            }
        }

        setFilteredPosts(filtered);
    }
}
