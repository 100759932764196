import React, {useEffect, useState, useRef} from 'react';
import './style.scss';
import config from './config.json';
import media from '../../media';
import Layout from "../../components/layout/main";
import AnimationOnScroll from "../../components/animation-on-scroll/main";
import SplashScreen from "../../components/splash-screen/main";
import SectionHeading from "../../components/section-heading/main";
import ProjectsGallery from "../../components/projects-gallery/main";
import VideoScreen from "../../components/video-screen/main";
import Carousel from "../../components/carousel/main";

export default function Home(props) {
    const [showScrollArrows, setShowScrollArrows] = useState(true);

    return (
        <div className="home">
            <Layout>
                <div className="intro-block">
                    <div className="screen1">
                        <VideoScreen
                            content={config.video}
                            onVideoPlay={()=>{setShowScrollArrows(false)}}
                            onVideoStop={()=>{setShowScrollArrows(true)}}
                        />

                        {showScrollArrows &&
                            <div className="inner-content">
                                <div className="arrows-holder">
                                    <span className="arrow"></span>
                                    <span className="arrow"></span>
                                    <span className="arrow"></span>
                                </div>
                            </div>
                        }
                    </div>

                    <div className="screen2">
                        <div className="inner-content">
                            <div className="bg-img"></div>

                            <SplashScreen content={config.splash} />
                        </div>
                    </div>
                </div>

                <div className="projects-block">
                    <div className="inner-content">
                        <SectionHeading text={config.projects.heading} />

                        <ProjectsGallery />
                    </div>
                </div>

                <div className="clients-block">
                    <div className="inner-content">
                        <SectionHeading text={config.clients.heading} />
                    </div>

                    <div className="logos">
                        <Carousel scrollDirection={"left"} scrollDuration={36}>
                            {config.clients.logos.map((logo, key) => {
                                return(
                                    <div className="logo" key={key}>
                                        <img src={media[logo]} />
                                    </div>
                                );
                            })}
                        </Carousel>
                    </div>
                </div>
            </Layout>
        </div>
    )
}
