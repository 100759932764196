import React, {useEffect, useState} from 'react';
import './style.scss';
import config from './config.json';
import media from '../../media';
import Layout from "../../components/layout/main";
import MainHeading from "../../components/main-heading/main";
import SectionHeading from "../../components/section-heading/main";

export default function Terms(props) {
    //On component mount
    useEffect(() => {
        document.title = config.title;
    }, []);

    return (
        <div className="terms">
            <Layout>
                <div className="terms-wrapper">
                    <div className="inner-content">
                        <MainHeading content={config.heading} />

                        <div className="blocks">
                            {config.blocks.map((block, i) => {
                                var blockAttributes = {
                                    className: "block",
                                    key: i
                                }

                                if (block.hasOwnProperty("id")) {
                                    blockAttributes.id = block.id;
                                }

                                return(
                                    <div { ...blockAttributes }>
                                        <SectionHeading text={block.heading} />

                                        <div className="paragraphs">
                                            {block.body.map((paragraph, j) => {
                                                return(
                                                    <p key={j} dangerouslySetInnerHTML={{__html:paragraph}}></p>
                                                );
                                            })}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </Layout>
        </div>
    )
}
