import React, {useEffect, useState} from 'react';
import './style.scss';
import media from '../../media';

export default function ProjectsLink(props) {
    return (
        <a className="project-link" href={props.content.url}>
            <span className="img-holder">
                <img src={media[props.content.img]}/>
                <span className="overlay"></span>
            </span>

            <span className="brand">{props.content.brand}</span>
            <span className="title">{props.content.title}</span>
        </a>
    );
}
