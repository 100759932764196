import React, {useEffect, useState} from 'react';
import './styles/general.scss';
import { BrowserRouter, Routes, Route } from "react-router-dom";

//Pages
import Home from "./pages/home/main";

import Works from "./pages/works/main";
import Services from "./pages/services/main";
import About from "./pages/about/main";
import Blog from "./pages/blog/main";
import Contact from "./pages/contact/main";
import Terms from "./pages/terms/main";

import Post from "./pages/blog/post/main";

import Afvs from "./pages/works/afvs/main";
import Arkana from "./pages/works/arkana/main";
import Chr from "./pages/works/chr/main";
import GrModels from "./pages/works/gr-models/main";
import Ioniq6 from "./pages/works/ioniq6/main";
import Synthesia from "./pages/works/synthesia/main";
import Hybrid from "./pages/works/hybrid/main";
import Gdpr from "./pages/works/gdpr/main";
import VehicleWraps from "./pages/works/vehicle-wraps/main";
import GrYaris from "./pages/works/gr-yaris/main";
import DigitalTransformation from "./pages/works/digital-transformation/main";

import NotFound from "./pages/not-found/main";

export default function Web() {

    //On component mount
    useEffect(() => {
        var hash = window.location.hash;

        if (hash!=="") {
            hash = hash.slice(1); //Remove first character
            var element = document.getElementById(hash);

            if (element!==null) {
                var pos = element.getBoundingClientRect().top - 90; //Substract 90px from topbar

                window.scroll({top:pos, behavior:'smooth'});
            }
        }
    }, []);

    return (
        <BrowserRouter basename="/">
            <Routes>
                <Route index element={<Home />} />

                <Route path="works" element={<Works />} />
                <Route path="services" element={<Services />} />
                <Route path="about" element={<About />} />
                <Route path="blog" element={<Blog />} />
                <Route path="contact" element={<Contact />} />
                <Route path="terms" element={<Terms />} />

                <Route path="blog/:slug" element={<Post />} />

                <Route path="works/afvs" element={<Afvs />} />
                <Route path="works/arkana" element={<Arkana />} />
                <Route path="works/chr" element={<Chr />} />
                <Route path="works/gr-models" element={<GrModels />} />
                <Route path="works/ioniq6" element={<Ioniq6 />} />
                <Route path="works/synthesia" element={<Synthesia />} />
                <Route path="works/hybrid" element={<Hybrid />} />
                <Route path="works/gdpr" element={<Gdpr />} />
                <Route path="works/vehicle-wraps" element={<VehicleWraps />} />
                <Route path="works/gr-yaris" element={<GrYaris />} />
                <Route path="works/digital-transformation" element={<DigitalTransformation />} />

                <Route path="*" element={<NotFound />} />
            </Routes>
        </BrowserRouter>
    )
}
