export async function getWpDocs(type, body) {
    var getString = "?";
    var properties = Object.keys(body);
    var i;

    //convert object into get string
    for (i=0; i<properties.length; i++) {
        if (body[properties[i]]!==null) {
            getString += properties[i]+"="+body[properties[i]]+"&";
        }
        else {
            getString += properties[i]+"&";
        }
    }

    getString = getString.substring(0, getString.length-1);

    //Fetch data with get string
    const response = await fetch("https://www.ignitiononline.com/archive/wp-json/wp/v2/"+type+""+getString);

    if (response.status!==200) {
        return -1;
    }
    else {
        return response.json();
    }
}

export async function sendContactEmail(body) {
    var formData = new FormData();
    var properties = Object.keys(body);
    var i;

    for (i=0; i<properties.length; i++) {
        formData.set(properties[i], body[properties[i]]);
    }

    const response = await fetch('https://formspree.io/f/xqkgraep', {
        method: 'POST',
        body: formData,
        headers: {'Accept': 'application/json'}
    });

    return response;
}

export function simplifyWpDate(str) {
    var monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    var obj = new Date(str);
    var simple = obj.getDate()+" "+monthNames[obj.getMonth()]+" "+obj.getFullYear();

    return simple;
}