import React, {useEffect, useState, useRef} from 'react';
import './style.scss';
import media from '../../media';

export default function VideoScreen(props) {
    const [videoOverlay, setVideoOverlay] = useState(true);
    const [videoControls, setVideoControls] = useState(false);
    const [videoMuted, setVideoMuted] = useState(true);

    const refVideo = useRef(null);

    return (
        <div className="video-screen">
            <video ref={refVideo} controls={videoControls} controlsList="nodownload" autoPlay muted={videoMuted} onEnded={onVideoEnded}>
                <source src={media[props.content.src]} type="video/mp4" />
            </video>

            {videoOverlay &&
                <div className="overlay">
                    <div className="inner-content">
                        <div className="overlay-elements">
                            <div className="overlay-txt">
                                <span className="title" dangerouslySetInnerHTML={{__html:props.content.title}}></span>
                            </div>

                            <div className="overlay-btn">
                                <div className="play-btn" onClick={onPlayClick}>
                                    <div className="play-bg"></div>
                                    <div className="play-border"></div>
                                    <div className="play-content">
                                        <span className="arrow"></span>
                                        <span className="label" dangerouslySetInnerHTML={{__html:props.content.btn}}></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    );

    function onPlayClick(e) {
        refVideo.current.pause();
        refVideo.current.currentTime = 0;
        refVideo.current.play();

        setVideoOverlay(false);
        setVideoControls(true);
        setVideoMuted(false);

        if (props.onVideoPlay) {
            props.onVideoPlay();
        }
    }

    function onVideoEnded(e) {
        refVideo.current.pause();
        refVideo.current.currentTime = 0;
        refVideo.current.play();

        setVideoOverlay(true);
        setVideoControls(false);
        setVideoMuted(true);

        if (props.onVideoStop) {
            props.onVideoStop();
        }
    }
}
