import React, {useEffect, useState} from 'react';
import './style.scss';
import config from './config.json';
import media from '../../media';

export default function MainHeading(props) {
    return (
        <div className="main-heading">
            {props.content.back &&
                <a className="back-btn" href={props.content.back}>
                    <span className="arrow"></span>
                    <span className="label">{config.backBtn}</span>
                </a>
            }

            <div className={"title-holder"+(props.content.back?" has-back":"")+(props.content.extra?" has-extra":"")}>
                <h1 className="title" dangerouslySetInnerHTML={{__html:props.content.title}}></h1>
                {props.content.extra && <span className="extra" dangerouslySetInnerHTML={{__html:props.content.extra}}></span> }
            </div>
        </div>
    )
}
